html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f5f5f5 !important;
  font-family: 'Montserrat', sans-serif;
}

#root {
  background-color: #f5f5f5;
}

.intermitente {
  /* border: 1px solid black;
  padding: 20px 20px;
  box-shadow: 0px 0px 20px; */
  border-radius: 50%;
  animation: infinite resplandorAnimation 2s;

}

@keyframes resplandorAnimation {

  0%,
  100% {
    box-shadow: 0px 0px 20px;
  }

  50% {
    box-shadow: 0px 0px 0px;
  }
}

.confirmButtonSweet {
  background-color: #476EA5 !important;
  /* border-radius: 25px !important;  */
  border-color: #FFFFFF;
  min-width: 270px !important;
  letter-spacing: 2px;
  width: 270px !important;
  height: 45px !important;
  border: 1px solid #FFFFFF !important;
  border-radius: 100px !important;
  padding: 0;
  color: #FFFFFF !important;
  font-size: 16px !important;
  font-weight: 450;
}

.confirmButtonSweetWarning {
  background-color: #FFFFFF !important;
  border-color: #476EA5 !important;
  min-width: 270px !important;
  letter-spacing: 2px;
  width: 270px !important;
  height: 45px !important;
  border: 1px solid #476EA5 !important;
  border-radius: 100px !important;
  padding: 0;
  color: #476EA5 !important;
  font-size: 16px !important;
  font-weight: 450;
}

.confirmButtonSweetCustom {
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 25px !important;
  min-width: 270px !important;
}

.confirmButtonSweetConfirm {
  background-color: #476EA5 !important;
  /* border-radius: 25px !important;  */
  border-color: #FFFFFF;
  min-width: 185px !important;
  letter-spacing: 2px;
  width: 185px !important;
  height: 45px !important;
  border: 1px solid #FFFFFF !important;
  border-radius: 100px !important;
  padding: 0;
  color: #FFFFFF !important;
  font-size: 16px !important;
  font-weight: 450;
}

.confirmButtonSweetCancel {
  background-color: #FFFFFF !important;
  border-color: #476EA5;
  min-width: 185px !important;
  letter-spacing: 2px;
  width: 185px !important;
  height: 45px !important;
  border: 1px solid #476EA5 !important;
  border-radius: 100px !important;
  padding: 0;
  color: #476EA5 !important;
  font-size: 16px !important;
  font-weight: 450;
}

.confirmButtonSweet:focus,
.confirmButtonSweetCustom:focus,
.confirmButtonSweetConfirm:focus,
.confirmButtonSweetCancel:focus {
  box-shadow: none !important;
}

.confirmButtonSweetWarning:focus,
.confirmButtonSweetWarning:focus {
  box-shadow: none !important;
}

.swal2-container {
  z-index: 1200 !important;
}

.react-tel-input .form-control {
  width: 100%;
  height: 45px;
  border-radius: 13px;
  font-size: 16px;
}

.swal2-popup {
  max-width: 600px;
  padding: 0 0 1.25em !important;
}

.swal2-icon-alertNoti {
  border: none;
  border-color: #FFFFFF !important;
  width: 90% !important;
  /* height: 30px !important; */
  /* width: 7em !important;
  height: 6em !important; */
  /* margin: .1em auto .1em !important; */
  margin: 2.5em auto 0.6em !important;
}

.swal2-icon .swal2-icon-content {
  max-width: 20%;
  justify-content: center;
}

.swal2-icon-content-img {
  /* padding-top: 0.5em; */
  /* width: 130px;
  height: 130px; */
}

.swal2-icon-content {
  /* width: 150px;
  height: 150px; */
}

.tituloNotificacion {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  padding-top: 10px !important;
  font-size: 26px;
  line-height: 22px;
  letter-spacing: 1.2px;
}

.dstoast {
  padding: 8px !important;
}

.dstoast .swal2-title {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.imageFlujoExpediente {
  min-height: 160px;
}

@media only screen and (max-width: 812px) {
  .imageFlujoExpediente {
    min-height: 50px;
  }

  .swal2-popup {
    max-width: 280px;
  }

  .swal2-icon .swal2-icon-content {
    max-width: 100%;
    justify-content: center;
  }

  .swal2-icon-content-img {
    width: 70px !important;
    height: 60px !important;
  }

  .tituloNotificacion {
    font-family: 'Montserrat', sans-serif !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    font-size: 20px !important;
    letter-spacing: 1.2px !important;
  }

  .swal2-html-container {
    margin: 1em 0.6em .3em;
    font-size: 15px;
  }

  .confirmButtonSweet {
    background-color: #476EA5 !important;
    /* border-radius: 25px !important;  */
    min-width: 240px !important;
    width: 240px !important;
    height: 38px !important;
    border: 1px solid #FFFFFF !important;
    border-radius: 100px !important;
    padding: 0;
    color: #FFFFFF !important;
    font-size: 14px !important;
  }

  .confirmButtonSweetConfirm {
    background-color: #476EA5 !important;
    /* border-radius: 25px !important;  */
    border-color: #FFFFFF;
    min-width: 185px !important;
    letter-spacing: 2px;
    width: 185px !important;
    height: 45px !important;
    border: 1px solid #FFFFFF !important;
    border-radius: 100px !important;
    padding: 0;
    color: #FFFFFF !important;
    font-size: 16px !important;
    font-weight: 450;
  }

  .confirmButtonSweetCancel {
    background-color: #FFFFFF !important;
    border-color: #476EA5;
    min-width: 185px !important;
    letter-spacing: 2px;
    width: 185px !important;
    height: 45px !important;
    border: 1px solid #476EA5 !important;
    border-radius: 100px !important;
    padding: 0;
    color: #476EA5 !important;
    font-size: 16px !important;
    font-weight: 450;
  }

  .swal2-icon-alertNoti {
    border: none;
    border-color: #FFFFFF !important;
    width: 100% !important;
    /* height: 30px !important; */
    /* width: 7em !important;
    height: 6em !important; */
    margin: .1em auto .1em !important;
  }

}